import { ActivityFeed, NullOr } from "@regal-voice/shared-types";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import { stringify } from "qs";

import { configureServer } from "Services/AbstractApiService";

import { getEventTypeViewConfig } from "../ActivityEventService";
import { deepCaseConversion } from "../HelpersService";
import { getIngestUrl } from "../ProxyConfig";

import type { Profile } from "Types/Profile";
import type { RecentActivity } from "Types/RecentActivity";
import type { Webhook } from "Types/Webhook";

const makeRequest = ({ performCaseConversion = true, performResponseCaseConversion = true } = {}) => {
    return configureServer({
        host: getIngestUrl(),
        performCaseConversion,
        performResponseCaseConversion,
    });
};

const makeContactRequest = ({ performCaseConversion = true }) => {
    return configureServer({
        host: getIngestUrl(),
        performCaseConversion,
        performResponseCaseConversion: false,
    });
};

type ApiResponse = {
    message: string;
};

export async function updateSubscription({
    subscribe,
    channel,
    source,
    phone,
    email,
    profileId,
}: {
    subscribe: boolean;
    channel: string;
    source: string;
    phone?: string;
    email?: string;
    profileId?: string;
}): Promise<ApiResponse> {
    if (!phone && !profileId) {
        throw new Error("Tried to update subscription status without passing a proper identifier.");
    }
    const path = subscribe ? "subscribe" : "unsubscribe";
    return makeRequest()<ApiResponse>({
        endpoint: `/v1/users/${path}`,
        errors: {
            notFound: "Contact not found",
            other: "Error updating subscription",
        },
        body: {
            channel,
            source,
            ...(phone && { phone }),
            ...(email && { email }), // Do we need email? Don't think it's currently used on ingest side
            ...(profileId && { profileId }),
        },
    });
}

export function getUpdateAttributeUrl(profileId: string) {
    const base = "/v1/profiles";

    if (profileId) {
        return `${base}/profile/${profileId}`;
    }
    throw new Error("Can't update user attribute, no profileId provided");
}

export async function updateAttribute({
    attribute,
    performCaseConversion,
    profileId,
}: {
    attribute: Record<string, any>;
    performCaseConversion?: boolean;
    profileId: string;
}): Promise<Profile> {
    const reqAttributes = Object.keys(attribute).reduce((acc, key) => {
        const attrKey = key === "customProperties" || key === "rvProperties" ? snakeCase(key) : key;
        return {
            ...acc,
            [attrKey]: attribute[key],
        };
    }, {});
    return makeContactRequest({ performCaseConversion })<
        Profile & { custom_properties: Record<string, any>; rv_properties: Record<string, any> }
    >({
        endpoint: getUpdateAttributeUrl(profileId),
        errors: {
            notFound: "Attribute not found",
            other: "Error updating attribute",
        },
        body: {
            ...reqAttributes,
        },
        method: "PATCH",
    }).then((contact) => {
        const { custom_properties: customProperties, rv_properties: rvProperties, ...rest } = contact;
        return {
            ...deepCaseConversion(rest, camelCase),
            customProperties,
            rvProperties,
        };
    });
}

export async function taskSnooze({
    taskSid,
    snoozeUntil,
}: {
    taskSid: string;
    snoozeUntil: string;
}): Promise<ApiResponse> {
    return makeRequest()<ApiResponse>({
        endpoint: `/voice/snooze-task`,
        errors: {
            notFound: "Could not be found",
            other: "Error snoozing task",
        },
        body: {
            taskSid,
            snoozeUntil,
        },
    });
}

export async function activityFeed({ limit, profile }: { limit?: number; profile: string }): Promise<ActivityFeed> {
    if (!profile) {
        throw new Error("Tried fetching Activity Feed with no valid identifier");
    }
    const identifierWithoutDashes = profile.replace(/-/gi, "");
    let endpoint = `/v1/events/profile_feed/profile/${identifierWithoutDashes}`;

    if (Number.isInteger(limit)) {
        endpoint += `?limit=${limit}`;
    }
    return makeRequest()<ActivityFeed>({
        endpoint,
        errors: {
            notFound: `Could not load activity feed for ${identifierWithoutDashes}`,
            other: `Could not load activity feed for ${identifierWithoutDashes}`,
        },
        retryAttempts: 5,
    });
}

export async function getRecentActivity({
    lookbackOverride,
}: {
    lookbackOverride: NullOr<number>;
}): Promise<RecentActivity[]> {
    return makeRequest({ performResponseCaseConversion: false })<RecentActivity>({
        endpoint: `/v1/events/profile_recent${lookbackOverride ? "/" + lookbackOverride : ""}`,
        errors: {
            notFound: "Could not load Recent Activity feed",
            other: "Error fetching Recent Activity",
        },
    }).then((result) =>
        result.events.map((event: RecentActivity) => ({
            ...event,
            mappedName: getEventTypeViewConfig(event).text,
        }))
    );
}

export async function profileEvents({
    profileId,
    event_type,
    event_name,
    params,
}: {
    profileId?: string;
    event_type?: string;
    event_name?: string;
    params?: {
        limit?: number;
        next_iterator?: string | null;
    };
}): Promise<any> {
    const queryParams = stringify(params);

    let endpoint = `/v1/events/profile-events/profile/${profileId?.replace(/-/gi, "")}`;
    endpoint += event_type ? `/${event_type}` + (event_name ? `/${event_name}` : "") : "";

    return makeRequest({ performResponseCaseConversion: false })<any>({
        endpoint: `${endpoint}?${queryParams}`,
        errors: {
            notFound: `Could not load event history for ${profileId}`,
            other: `Could not load event history for ${profileId}`,
        },
    });
}

export async function trackCancelAllCampaignsTasks({
    phone,
    details,
    profileId,
}: {
    phone: string;
    details: Record<string, any>;
    profileId: string;
}): Promise<ApiResponse> {
    return makeRequest()<ApiResponse>({
        endpoint: `/internal-events`,
        errors: {
            notFound: "Could not be found",
            other: "Error cleaning up chats",
        },
        body: {
            profile_id: profileId,
            name: "Cancel All Automated Tasks",
            contact_phone: phone,
            event_type: "track",
            properties: {
                ...details,
            },
        },
    });
}

export async function getEventsWebhooks(): Promise<Webhook[]> {
    return makeRequest()<Record<string, any>>({
        endpoint: `/v1/webhooks`,
        errors: {
            notFound: "Could not load Webhooks",
            other: "Error fetching Webhooks",
        },
    }).then((result) => {
        // convert response from dict {[id]: {host, apiKey}} to array [{id, host, apiKey}]
        return Object.entries(result || {}).map(([id, val]) => ({ id: id.toLowerCase(), ...val }));
    });
}

export async function createEventsWebhook({
    host,
    apiKey,
    header,
}: {
    host: string;
    apiKey?: string;
    header?: string;
}): Promise<ApiResponse> {
    return makeRequest()<ApiResponse>({
        endpoint: `/v1/webhooks`,
        errors: {
            notFound: "Could not create Webhook",
            other: "Error creating Webhook",
        },
        body: {
            host,
            apiKey,
            header,
        },
    });
}

export async function updateEventsWebhook(webhook: Webhook): Promise<ApiResponse> {
    return makeRequest()<ApiResponse>({
        endpoint: `/v1/webhooks`,
        errors: {
            notFound: "Could not update Webhook",
            other: "Error updating Webhook",
        },
        body: webhook,
        method: "PUT",
    });
}

export async function removeEventsWebhook({ id }: { id: string }): Promise<ApiResponse> {
    return makeRequest()<ApiResponse>({
        endpoint: `/v1/webhooks`,
        errors: {
            notFound: "Could not load Webhooks",
            other: "Error removing Webhook",
        },
        body: {
            id,
        },
        method: "DELETE",
    });
}
